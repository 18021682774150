/* Override Bootstrap variables */
.mss-loader .mss-loader-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5); }

.mss-loader .mss-loader-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border: solid 1px #666;
  opacity: 1; }
