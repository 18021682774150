.mss-color-picker .mss-color-picker-pill {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  cursor: pointer;
  border: solid 1px black; }
  .mss-color-picker .mss-color-picker-pill:hover {
    box-shadow: 0 0 0 0.2rem rgba(22, 13, 79, 0.25); }

.mss-color-picker .mss-color-picker-popover {
  position: absolute;
  z-index: 2; }

.mss-color-picker .mss-color-picker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
